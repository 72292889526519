/* Base styles */
body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1200px; /* Restrict max width for better readability */
  width: 100%; /* Full width */
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap; /* Allow wrapping */
  flex-direction: row; /* Default row direction */
  justify-content: space-between;
  padding: 10px;
  border-radius: 10px;
  overflow: hidden;
}

/* Image and Info Sections */
.product-image,
.product-info {
  flex-basis: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  overflow: hidden;
}

.product-image {
  flex: 2;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  margin-bottom: 20px; /* Add spacing on narrow screens */
}

.product-info {
  flex: 1;
  width: 100%;
  color: #333;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  padding: 15px;
}

/* Images */
.product-image-small,
.product-image-overview {
  height: auto;
  flex-grow: 1;
  border-radius: 10px;
  overflow: hidden;
}

.product-image-small {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Full width */
  max-height: 300px; /* Constrain max height */
  margin-bottom: 20px;
  scrollbar-width: thin; /* Firefox scrollbar styles */
}

.product-image-small img {
  width: 50px; /* Thumbnail size */
  height: auto;
  margin-bottom: 4px;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.product-image-overview {
  flex: 3;
  width: 100%;
}

.product-image-overview img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 10px;
}

/* Typography */
.product-info h1 {
  color: rgb(250, 135, 145);
  font-size: 1.8em; /* Adjusted for responsiveness */
  margin-bottom: 10px;
}

.rating {
  color: rgb(250, 135, 145);
  margin: 10px 0;
  font-size: 1.2em;
}

.description {
  margin: 20px 0;
  font-size: 1em;
  line-height: 1.5;
}

.price {
  font-size: 2.5em;
  margin: 20px 0;
  color: #333;
}

/* Price Options */
.fixed-price-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  width: 100%; /* Full width */
}

.custom-price-option-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
}

/* Input */
input[type="text"] {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1em;
}

/* Buttons */
.submit-order {
  width: 100%;
  padding: 15px;
  border-radius: 50px;
  background-color: rgb(250, 135, 145);
  font-size: 1em;
  color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-order:hover {
  background-color: #555;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .container {
      flex-direction: column; /* Stack vertically */
      padding: 10px;
  }

  .product-image {
      flex: none;
      width: 100%;
  }

  .product-info {
      flex: none;
      width: 100%;
      margin-top: 20px;
  }

  .product-image-small {
      max-height: 200px;
  }

  .product-info h1 {
      font-size: 1.5em;
  }

  .price {
      font-size: 2em;
  }

  .submit-order {
      font-size: 0.9em;
      padding: 10px;
  }
}

@media (max-width: 768px) {
  .container {
      padding: 10px;
  }

  .product-image {
      width: 100%;
  }

  .product-info {
      width: 100%;
  }

  .product-info h1 {
      font-size: 1.2em;
  }

  .price {
      font-size: 1.8em;
  }

  .submit-order {
      font-size: 0.8em;
      padding: 8px;
  }
}

@media (max-width: 480px) {
  .container {
      padding: 5px;
  }

  .product-image {
      width: 100%;
  }

  .product-info {
      width: 100%;
      padding: 10px;
  }

  .product-info h1 {
      font-size: 1em;
  }

  .price {
      font-size: 1.5em;
  }

  .submit-order {
      font-size: 0.7em;
      padding: 6px;
  }
}
