/*.card {
    h4 {
        text-align: center;
    }
    padding: 20px;
    border-radius: 20px;
    .round-btn {
        border-radius: 10px;
    }
}*/

.outer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  width: 180%;
}

.registerFrame {
  padding: 10px;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  width: 80%;
justify-content: center;
  align-items: center;
}

.registrationForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;

  margin-left: 50px;
}


.formGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

label {
  margin-bottom: 5px;
}

input {
  padding: 10px;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.registerFrame button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #ff4d00;
  border-color: #ff4d00;
  color: #fff;
  cursor: pointer;
  width: 100%;
  max-width: 400px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.registerFrame p {
    text-align: center;
}
.registerFrame h1 {
    text-align: center;
}
