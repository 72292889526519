
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
    padding: 20px;
border-radius: 20px;
    background-color: #ffffff;
    box-shadow: 0 0 5px rgb(0 0 0 / 10%);
    margin-top: 50px;
text-align: center;
}

.form1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.formInput {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
}

.label1 {
  font-size: 14px;
  color: gray;
  margin-bottom: 5px;
}

input[type="password"],
.select1 {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid gray;
  width: 100%;
  box-sizing: border-box;
}

.button1 {
  padding: 10px;
  background-color: #0077cc;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
margin-top: 10px;
}

button:disabled {
  background-color: gray;
  cursor: default;
}

button.disabled {
  opacity: 0.5;
  cursor: default;
}
