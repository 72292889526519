/* forum.css */

*{
    box-sizing: border-box;
}

/* html{
    font-size: 14px;
    font-family: 'Titillium Web', sans-serif;
    background-color:rgb(0,0,0);
    color:#FEFEFE;
} */

/* a{
    color:white;
    font-weight: bolder;
    text-decoration: none;
}

h1{
    font-size:16px;
    font-weight: bolder;
} */

.form-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .form-container label {
    display: block;
    font-size: 1rem;
    margin-bottom: 5px;
    color: black;
  }
  
  .form-container select
  .form-container input[type="text"] {
    width: 100%; /* Makes the input field take full width of its container */
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .form-container textarea {
    width: 100%;
    padding: 1px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form-container button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  button[type="submit"] {
    background-color: #007bff;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button[type="submit"]:hover {
    background-color: #0056b3;
  }
  
  button[type="submit"]:active {
    background-color: #004080;
  }
  
  button[type="submit"]:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  
  .form-container button:hover {
    background-color: #0056b3;
  }
  
  #login_content {
    max-width: 400px;
    margin: 0 auto ;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  #line {
    display: block;
    margin-top: 20px;
    font-size: 14px;
    color: #333;
  }
  
  #line a {
    color: #007bff;
    text-decoration: none;
  }
  
  #line a:hover {
    text-decoration: underline;
  }
  
  .errmsg {
    color: red;
    margin-bottom: 10px;
  }
  
  .offscreen {
    display: none;
  }

  .container{
    margin: 20px;
    padding: 20px;
}
.subforum{
    margin-top: 0 auto;
}
.subforum-title{
    background-color: white;
    margin: 0 auto;
    padding: 2px;
    border-radius: 5px;
    
}

.subforum-column{
    padding: 10px;
    margin: 4px;
    border-radius: 5px;
    background-color: white;
}

.subforum-description *{
    margin-block: 0;
}

.center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.subforum-icon i{
  font-size: 45px;
}

.subforum-devider{
  display: block;
}

.form-category {
    margin: auto;
}
#forum-info{
  max-width: 400px;
  margin: 0 auto ;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}