
.copyright {
	margin: 30px auto 0;
	width: 100%;
}

.footer {
    z-index: 999999999999999!important;
}

