
.forgetPasswordForm {
  height: 100vh;
  width: 100vw;
  padding-top: 150px;
  background-color: #e0e0e0;
}

.formGroup:hover {
  box-shadow: 0 0 5px rgba(255, 77, 0, 0.4);
}

.formGroup {
  border: 1px solid;
  padding: 10px;
  border-radius: 20px;
  background-color: #ffffff;
  transition: box-shadow 0.5s ease;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.0);
  border-color: #e3e8ee;
  max-width: 400px;
  width: 50%;
  margin: auto;
}

.keyField:hover {
  box-shadow: 0 0 5px rgba(255, 77, 0, 0.4);
}

.keyField {
  border: 1px solid #e3e8ee;
  border-radius: 20px;
  transition: box-shadow 0.5s ease;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.0);
  padding: 10px;
  max-width: 400px;
  background-color: #ffffff;
  width: 50%;
  margin: 50px auto auto auto;
}

.inputBox {
  border: 1px solid #e3e8ee;
}

.inputBox::selection {
  border: 1px solid #ff4d00;
}

.formButton {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #ff4d00;
  border-color: #ff4d00;
  color: #fff;
  cursor: pointer;
  width: 100%;
  max-width: 400px;
  margin-top: 20px;
  margin-bottom: 20px;
  transition: box-shadow 0.3s ease;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.0);
}

.formButton:hover {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.8);
}
