
.searchbar {
    display: flex;
    flex-direction: row;
    height: 2.5rem;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    border-radius: 18px;
    padding: 0.5rem;
    align-items: center;
    justify-content: center;
    font-size:14px;
}

.input {
    box-shadow: none;
    border: none;
    outline: none;
    font-size: 14px;
    width: 120px;
    height: 100%;
}

.searchButton {
    height: 30px;
    max-width: 100px;
    background-color: #fff;
    border: 1px solid #ff4d00;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#ff4d00;
    text-decoration: none;
    transition: color 0.2 ease;
}
  
.searchButton:hover {
    background-color: #ff4d00;
    color: #fff;
}

.customDropdown {
    position: relative;
    cursor: pointer;
    padding-right: 20px;
}
  
.dropdownHeader {
    display: flex;
    align-items: center;
    width: 50px;
    margin-right: 5px;
}
  
.dropdownOptions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding-left: 2px;
    margin-top: 5px;
    background-color: #fff;
    border: 1px solid #ff4d00;
    border-radius: 5px;
    font-size: 12px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1;
}
  
.dropdownOption {
    list-style-type: none;
    margin: 7px 8px;
    padding-left: 5px;
    cursor: pointer;
    border-left: solid 5px lightblue;
}

.dropdownOption:hover {
    border-left: solid 5px rgb(68, 201, 245);
}
  
.arrowUp,
.arrowDown {
    margin-left: 5px;
}
  
.arrowUp {
    transform: rotate(-180deg);
}

.categoryContainer {
    width: 55px;
}

