
.userPasswordPage {
    background-color: #ffffff;
    border: solid 1px;
    border-color: #ff4d00;  border-radius: 5px;
    position: fixed;
    width: 70%;
    height: 70%;
    padding: 10px;
}

.title {
    font: sans-serif;
    font-size: 30px;
    color: #ff4d00;
    font-weight: 400;
    border: none;
    outline: none;
    margin-left: 10px;
    margin-top: 0px;
    box-shadow: none;
}

.profileTop {
    border-bottom: solid 1px;
    border-color: rgba(0, 0, 0, 0.5);
    padding: 5px;
    margin-bottom: 20px;
    display: flex;
}

.subtitle {
    font: sans-serif;
    font-weight: 700;
    font-size: 14px;
    color: #ff4d00;
}

.margin {
    margin-left: 5%;
}

.updateButton {
    background-color: #ff4d00;
    float: right;
    border: none;
    margin-right: 5%;
    color: white;
    padding: 7px 16px;
    z-index:5;
    border-radius: 3px;
    margin-top: 4.0%;
    float: right;
    margin-right: 5%;
}
  
@media (max-width: 768px) {
    .userPasswordPage {
      width: 90%;
      position: relative;
    }
}