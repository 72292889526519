.img-upload {
    display: block;
}

ul {
    list-style-type: none;
}

.img-upload ul li {
    display: inline-block;
    /* float: left; */
    overflow: hidden;
    width: 46%;
    margin: 2%;
    padding: 0;
    height: 130px;
    border-radius: 5px;
    text-align: center;
    line-height: 130px;
    vertical-align: middle;
}

.img-upload ul li div {
    height: 100%;
}

li span {
    display: block;
    color: #57636f;
    padding: 0;
}

.photo {
    background-position: 50%;
    background-size: cover;
}

li.upload {
    border: 2px dashed #57636f;
    font-size: 1.5em;
    text-align: center;
    width: 100%;
}

p {
    font-size: 12px;
    color: #57636f;
}

.ivu-icon {
    font-family: Ionicons;
    /* speak: none; */
    font-style: normal;
    font-weight: 400;
    font-size: 1.5em;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    vertical-align: middle;
}