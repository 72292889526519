
.contactPage {
    background-color: #ffffff;
    border: solid 1px;
    border-color: #ff4d00;  border-radius: 5px;
    position: fixed;
    width: 70%;
    height: 70%;
    padding: 10px;
}

.contactTop {
    border-bottom: solid 1px;
    border-color: rgba(0, 0, 0, 0.5);
    padding: 5px;
    margin-bottom: 20px;
}

.contactBottom {
    margin-left: 5%;
    margin-right: 5%;
    height: 40vh;
    display: flex;
    flex-direction: column;
}

.title {
    font: sans-serif;
    font-size: 30px;
    color: #ff4d00;
    font-weight: 400;
    border: none;
    outline: none;
    margin-left: 10px;
    margin-top: 0px;
    box-shadow: none;
}

.buttonsContainer {
    display: flex;
    flex-direction: row;
    margin-top: 2vh;
    width: 20vw;
}

.updateButton {
    background-color: #ff4d00;
    border: none;
    color: white;
    padding: 7px 16px;
    float:right;
    margin-right: 20px;
    z-index:5;
    border-radius: 3px;
}

.addButton {
    background-color: #ff4d00;
    float: right;
    border: none;
    margin-right: 5%;
    color: white;
    padding: 7px 16px;
    z-index:5;
    border-radius: 3px;
}

.cancelButton {
    background-color: #ff4d00;
    border: none;
    color: white;
    padding: 5px 12px;
    z-index:5;
    border-radius: 3px;
    margin-right: 10px;
    margin-left:auto;
}

.confirmButton {
    background-color: #ff4d00;
    border: none;
    color: white;
    padding: 5px 12px;
    margin-right: 15px;
    z-index:5;
    border-radius: 3px;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 200;
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.popupContent {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    z-index:201;
}
  
.contactCards {
    padding-left: 1%;
    transition: background-color 0.06s ease-in;
}

.contactCards:hover {
    background-color: rgba(0, 0, 0, 0.2);
    
}

.inputField {
    height: 4vh;
    width: 100%;
    outline: none;
}


.inputField:focus {
    box-shadow: none;
}

@media (max-width: 768px) {
    .contactPage {
      width: 90%;
      position: relative;
    }
}