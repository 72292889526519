
.buttonContainer {
  display: flex;
  justify-content: space-between;
  bottom: 20px;
  width: 100%;
  z-index: 1;
}

.buttonLeft {
  margin-left: 20px;
}

.buttonRight {
  margin-right: 20px;
}

.searchContainer {
  margin-top: 4rem;
  margin-left: 10vw;
  margin-right: 10vw;
  align-items: center;
  justify-content: center;
  display: flex;
}

.background {
  padding-top: 10px;
}
