.userPage {
  padding-top: 0px;
  background-color: rgb(232, 232, 232);
  display: flex;
}

.userInterface {
  background-color: white;
  border-radius: 5px;
  color: #ff4d00;
  z-index: 0;
  margin-left: 8vw;
  margin-top: auto;
  margin-bottom: auto;
  width:15vw;
  margin-right: 3vw;
  border: solid 1px #ff4d00;
}

.userMenu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.userMenu li:last-child {
  border-bottom: none;
}

.userMenu li {
  display: block;
  padding: 15px 25px;
  color: #ff4d00;
  text-decoration: none;
  border-bottom: 1px solid #ff4d00;
  transition: background-color 0.05s ease-in;
  transition: border 0.05s ease-in;
}

.userMenu li:hover {
  background-color: rgba(255, 77, 0, 0.088);
}

.userMenuHorizontal {
  display: flex;
}

.userMenuHorizontal li {
  display: flex;
  justify-content: center;
  padding: 15px;
  width: 20%;
  border-right: 1px solid #ff4d00;
  text-align: center;
  font-size: 1rem;
}

.userMenuHorizontal li:last-child {
  border-right: none;
}

.menuSelected {

  border-right: 2px solid;
}

.userContent {
  padding-top: 10%;
  background-color: rgb(232, 232, 232);
  height: 100vh;
  width: 100vw;
}

.userPage.mobile {
  flex-direction: column;
  padding-top: 60px;
  overflow-x: hidden;
}

.userPage.mobile .userInterface {
  width: 90%;
  margin: auto;
}

.userPage.mobile .userContent {
  margin-top: -25px;
  display: flex;
  justify-content: center;
}