.container {
    padding: 10px; /* Keep padding minimal */
    margin: 20px 0 0 0; /* Reduce top margin */
    font-family: Raleway, Arial, Helvetica, sans-serif;
    box-sizing: border-box;
    color: #657180;
    display: block;
}

.container h1 {
    padding: 0; /* Remove extra padding */
    font-size: 1.8em; /* Adjust font size to reduce space */
    font-weight: 400;
    margin-block-start: 0.5em; /* Reduce top margin */
    margin-block-end: 0.5em; /* Reduce bottom margin */
}

.container ul {
    margin: 0;
    padding: 0;
}

.container ul li {
    margin: 10px 0; /* Reduce bottom margin */
    padding: 0;
    list-style-type: none; /* Keep list style clean */
}

.question {
    color: #198cff;
    margin: 0 0 4px; /* Reduce spacing below question */
}

.answer {
    margin: 0 0 10px; /* Add some spacing after each answer */
    font-size: 1em; /* Adjust font size for readability */
}

/* For responsive design and vertical stacking on smaller screens */
.faq-container {
    flex-direction: column;
}

.faq-question {
    margin-bottom: 1rem;
}

