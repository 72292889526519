/* Remove default margins and paddings */
html, body {
  margin: 0;
  padding: 0;
}

/* Wrapper for the entire cancel page */
.cancel-page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
  background-color: #ffffff; /* Optional background color */
}

/* Container for the content below the header */
.cancel-page-container {
  flex: 1;
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: flex-start; /* Align items to the top */
  align-items: center; /* Center items horizontally */
  padding: 20px;
  width: 100%; /* Ensure the container spans the full width */
}

/* Card styles */
.card {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 80%; /* Use percentage width for responsiveness */
  max-width: 600px; /* Limit the max width for large screens */
  text-align: center;
  margin-top: 20px; /* Add top margin to separate from header */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Heading styles */
.cancelPage_h1 {
  color: #ff4d00; /* Main color */
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 36px; /* Adjusted for smaller screens */
  margin-bottom: 10px;
  text-align: center;
}

/* Paragraph styles */
.cancelPage_p {
  color: #333;
  line-height: 1.6;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-size: 18px; /* Slightly smaller font size */
  margin: 0;
  text-align: center;
}

/* Home Button */
.home-btn {
  background-color: #fff; /* White background */
  color: #ff4d00; /* Orange text */
  border: 1px solid #ff4d00; /* Orange border */
  border-radius: 50px; /* Capsule shape */
  padding: 10px 30px;
  font-size: 1em; /* Adjusted font size */
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-top: 20px;
}

.home-btn:hover {
  background-color: #ff4d00;
  color: #fff;
}

.home-button-link {
  text-decoration: none; /* Remove underline from link */
}

/* Responsive Design */
@media (max-width: 768px) {
  .card {
      width: 90%; /* Expand the card width on smaller screens */
  }

  .cancelPage_h1 {
      font-size: 30px; /* Smaller heading font */
  }

  .cancelPage_p {
      font-size: 16px; /* Smaller paragraph font */
  }

  .home-btn {
      font-size: 0.9em; /* Reduce button font size */
      padding: 8px 20px; /* Adjust padding */
  }
}

@media (max-width: 480px) {
  .card {
      width: 95%; /* Expand card width further for narrow screens */
      padding: 15px; /* Reduce padding for compact fit */
  }

  .cancelPage_h1 {
      font-size: 24px; /* Smaller heading font */
  }

  .cancelPage_p {
      font-size: 14px; /* Smaller paragraph font */
  }

  .home-btn {
      font-size: 0.8em; /* Smaller button font */
      padding: 6px 15px; /* Adjust padding */
  }
}
