/* Basic styles */
* {
  box-sizing: border-box;
}

/* Background styles */
.bg {
  background: no-repeat center bottom / cover;
}

/* Container styles */
.outer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Login frame styles */
.loginFrame {
  padding: 10px;
  width: 50%;
  max-width: 400px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.loginFrame h1,
.loginFrame p,
.form-item,
.line {
  text-align: center;
}

/* Login content styles */
.login_content,
form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login_content {
  margin-top: 50px;
}

/* Form styles */
form label {
  margin: 10px 0;
}

form input {
  padding: 10px;
  width: 100%;
  max-width: 400px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

/* Login button styles */
.loginFrame button {
  padding: 10px;
  width: 100%;
  max-width: 400px;
  border: none;
  border-radius: 5px;
  background-color: #ff4d00;
  color: #fff;
  cursor: pointer;
  margin: 20px 0;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .loginFrame {
      width: 90%;
  }
  .login_links {
      display: flex;
      justify-content: space-around;
  }
}

.signInButton {
  background-color: #ff4d00;
  color: white;
  width: 100%;
  padding: 12px;
  margin: 8px 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.signInButton:hover {
  background-color: #005fa3;
}

.googleButton {
  background: transparent !important;
  color: transparent !important;
  border: 2px solid #ff4d00 !important;
  width: 100%;
  padding: 12px;
  margin: 8px 0;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.googleButton:hover {
  background: rgba(255, 77, 0, 0.05) !important;
  border-color: #ff4d00;
}

.googleText {
  color: #ff4d00;
}

.googleLogo {
  width: 18px;
  height: 18px;
  position: absolute;
  right: 16px;
}
