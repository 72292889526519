* {
  box-sizing: border-box;
}

.page-container {
  background-color: #e9e9e9;
}

.content-container {
  max-width: 60rem;
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: start;
  margin-left: auto;
  margin-right: auto;
}

.upload-container {
  width: 100%;
  margin-top: 4rem;
}

.card-container {
  background: #fff;
  border-radius: 0.3rem;
  /* font-size: 1rem; */
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease;
  margin: 0.4rem;
  margin-top: 4rem;
  border: #57636f solid 1px;
}

.title {
  text-align: center;
  font-size: 2em;
  margin-top: 1rem;
}

a {
  float: right;
  font-size: 0.8em;
  cursor: pointer;
  margin-top: -30px;
  text-decoration: none;
}

label,
input,
select,
option,
.ivu-btn-ghost {
  color: #57636f;
}

select,textarea,
input.ivu-input {
  display: inline-block;
  width: 100%;
  line-height: 1.5;
  padding: 4px 7px;
  margin: 0;
  font-size: 0.9em;
  border-radius: 4px;
  border: #57636f 1px solid;
  background-color: #fff;
  background-image: none;
  position: relative;
  cursor: text;
  transition: border 0.2s ease-in-out, background 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
}

textarea.ivu-input {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  font-size: 0.9em;
}

.ivu-btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  line-height: 1.5;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0.4rem 1rem;
  font-size: 0.75rem;
  transform: translateZ(0);
  transition: color 0.2s linear, background-color 0.2s linear,
    border 0.2s linear;
  /* color: #657180; */
  background-color: #f7f7f7;
  border-color: #d7dde4;
}

.ivn-btn-group {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.ivu-btn-group > .ivu-btn:first-child {
  border-bottom-left-radius: 0.3rem;
  border-top-left-radius: 0.3rem;
}

.ivu-btn-group > .ivu-btn:last-child {
  border-bottom-right-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.ivu-btn-ghost {
  /* color: #657180; */
  background-color: transparent;
  border-color: #d7dde4;
}

.ivu-btn-ghost:hover {
  border-color: #a6481f;
  color: #a6481f;
}

.ivu-btn-primary {
  border-color: #c35424;
  background-color: #c35424;
  color: #fff;
}

.ivu-btn-primary:hover {
  background-color: #a6481f;
  border-color: #a6481f;
}

.btn-set {
  padding: 0;
  margin: 0;
  text-align: center;
}

.btn-row {
  position: relative;
  margin-left: 0;
  margin-right: 0;
  height: auto;
  zoom: 1;
  display: flex;
}

span {
  display: block;
  color: #fff;
}

.ivu-btn-long {
  width: 100%;
}

#submit-button {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.ivu-form-block {
  margin: 0;
  padding: 1rem;
}

.ivu-form-block:not(:last-child) {
  border-bottom: 0.06rem solid #e3e8ee;
}

.ivu-form-block:last-child {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.ivu-form-item-content {
  position: relative;
  line-height: 3rem;
  font-size: 1rem;
}

.ivu-row {
  position: relative;
  margin-left: 0;
  margin-right: 0;
  height: auto;
  zoom: 1;
  display: flex;
  gap: 1em;
}

.ivu-col-span-12 {
  display: block;
  width: 50%;
}

.ivu-col-span-24 {
  display: block;
  width: 100%;
}

a {
  display: block;
  padding: 0.625rem;
  margin: 0;
  color: #c35424;
  background: transparent;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.2s ease;
}

textarea {
  width: 100%;
}

@media screen and (max-width: 40rem) {
  
  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .card-container {
    margin-top: auto;
  }
}