.contentWrapper {
    position: relative;
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 78.5vw;
    height: 500px;
    margin-bottom: 20px;
    overflow: hidden;
    border: 1px solid rgba(255,77,0,0.30);
    border-radius: 10px;
}
  
.content img {
    max-width: 100%;
    margin: 0 auto;
    width: auto;
    height: 500px;
}

.content button {
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.3s ease;
    color: white;
}

.content:hover button {
    opacity: 1;
}

.leftButton {
    left: 10px;
}

.rightButton {
    right: 10px;
}

.closeButton {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    opacity: 1;
    color: black;
}

@media (max-width: 768px) {
    .content img {
        width: auto;
        height: auto;
        max-height: 500px;
    }

    .leftButton {
        left: 5px;
    }
    
    .rightButton {
        right: 5px;
    }
}
  
.switchUser {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 0 auto;
    max-width: 78.5vw;
    margin-bottom: 20px;
}

.switchUserContent {
    font-size: 16px;
    padding: 10px 20px;
    background-color: orange;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    flex: 1 1 calc(33.333% - 40px);
    box-sizing: border-box;
}

.switchSelfContent {
    font-size: 16px;
    padding: 10px 20px;
    background-color: #00B2FF;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    box-sizing: border-box;
}

.userIdCont {
    display: inline-flex;
    align-items: center;
    gap: 10px;
}

.updateStatus {
    margin-left: 10px;
    height: 30px;
    min-width: 60px;
    background-color: #fff;
    border: 1px solid #ff4d00;
    border-radius: 18px;
    color:#ff4d00;
    text-decoration: none;
    transition: color 0.2 ease;
}

.updateStatus:hover {
    background-color: #ff4d00;
    color: #fff;
}

.addBook {
    display: inline-flex;
    align-items: center;
}

.addBook p {
    margin: 0;
}

/* Start Google Ads */
.adWrapper {
    margin: 20px auto;
    text-align: center;
    max-width: 100%;
    min-width: 300px;  /* Ensure minimum width for AdSense */
    z-index: 5;
    position: relative;
}

.adsbygoogle {
    display: block;
    min-height: 280px;
    min-width: 300px;  /* Ensure ad width */
}

.additionalInfo {
    width: 78.5vw;
    position: relative;
    z-index: 1;/* Lower than adWrapper */
}


/* Apply Targeted CSS to Hide the Floating Ad */
.adsbygoogle:not(.in-article-ad) {
    display: none !important;
}
/* End Google Ads */

