.chat-bubble {
  display: flex;
  justify-content: space-between;
}

.chat-bubble.sent {
  justify-content: flex-end;
}

.chat-bubble.received {
  justify-content: flex-start;
}

.message-content {
  max-width: 60%;
  padding: 10px;
  border-radius: 10px;
  margin: 5px;
}

.chat-bubble.sent .message-content {
  background-color: #dcf8c6; /* light green for sent messages */
}

.chat-bubble.received .message-content {
  background-color: #ffffff; /* white for received messages */
  border: 1px solid #ddd;
}

.userProfile {
  position: relative;
}

.popup {
  position: absolute;
  top: 0;
  left: 35px;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  width: 250px;
  height: 100px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 9;
}

.popupContent {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.popAvatar {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  border: 1px solid #ddd;
}

.popupName {
  font-size: 14px;
  margin-top: 6px;
}

.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.buttonGroup {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  gap: 8px;
}

.iconButton {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
}

.iconButton:hover {
  background-color: #f0f0f0;
}