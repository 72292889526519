.backButton {
    text-decoration: none;
    color: darkgray;
    font-size: 25px;
}

.backButton:hover {
    color: lightblue;
}

.detailPage {
    padding-top: 5%;
    padding-left: 10%;
    padding-right: 10%;
    background-color:white;
    display:flex;
    flex-direction: row;
    gap: 20px;
}

@media (max-width: 1000px) {
    .detailPage {
        flex-direction: column;
        align-items: center;
        padding: 5% 0;
    }

    .carouselContainer {
        display: flex !important;
        justify-content: center !important;
    }
    
    .carousel {
        order: 1;
        width: 80vw !important;
        max-width: 100%; 
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }
    
    .carouselImage {
        width: auto;
        height: 100%;
        max-height: 100%;
        object-fit: contain;
        margin: 0 auto;
    }

    .imageSelectPanel {
        order: 2;
        flex-direction: row !important;
        justify-content: flex-start;
        gap: 10px;
        width: 90vw;
        overflow-x: scroll;
        padding: 0 10px;
    }

    .infoDiv {
        order: 3;
        width: 90vw !important;
        max-width: 90vw !important;
        margin: 20px auto 0 auto;
        display: flex;
        justify-content: center;
    }
}

.carouselItem {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.carouselItemContainer {
    display: inline-block;
    width: 10vw;
    height: 15vh;
    border: solid 1px #ff4d00;
    border-radius: 8px;
    margin-bottom: 1vh;
    transition: box-shadow 0.3s ease-in-out;
}



.carouselIndicators li {
    background-color: lightgray;
}
.carouselIndicators .active {
    background-color: red;
}

.carouselImage{
    max-width: auto;
    height: 68vh;
    display: block;
    margin: 0 auto;
    z-index: -5;
}

.infoDiv {
    max-width: 25vw;	
    min-width: 25vw;
    margin-left: auto;
    background-color: #fec889;
    border-radius: 4px; 
    display: flex;
    flex-direction: column;
    padding: 5px;
}

.infoInternal {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
    border-bottom: 1px solid;
    border-color: rgba(255, 77, 0, 0.5); 
    white-space: wrap;
    word-break: break-all;
}

.infoInternal:first-child {
    display: flex;
}

.infoInternal:last-child {
    border: none;
}

.priceInfo {
    font-size: 30px;
    color: rgb(255, 157, 0);
}

.priceOriginal {
    margin-left: 1vw;
    font-size: 15px;
    text-decoration: line-through;
    color: #00B2FF;
}

.tagButton {
    background-color: #00B2FF;
    text-decoration: none;
    border-radius: 4px; 
    font-size: 12px;
    margin-right: 2px;
    margin-bottom: 2px;
    padding: 3px 5px;
}

.seller {
    border-radius: 4px;
    text-align: left;
    color: #000;
}

.sellerAvatar {
    width: 30px;
    height: 30px;
    background-position: center;
    background-size: cover;
    display: inline-block;
    border-radius: 100%;
    margin: 0 5px 0 0;
}

.carousel {
    height: 70vh;
    width: 40vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fec889;
    border-radius: 8px;
    margin-left: 3%
}

.imageSelectPanel {
    display: flex;
    flex-direction: column;
}

.priceContainer {
    display: flex;
    flex-direction: row;
}

.publisherContainer {
    display: flex;
    flex-direction: row;
    color: #ff4d00;
    padding: 3px;
    transition: background-color 0.2s ease;
    border-radius: 3px;
}

.publisherContainer:hover {
    background-color: rgba(128, 217, 255, 0.8);
}

.anchor {
    text-decoration: none;
}

.soldButton {
    margin-left: auto;
    background-color: #ff4d00;
    font-size: 15px;
    height: 100%;
    padding: 0px 8px;
    border-radius: 3px;
    transition: background-color 0.1s ease;
}

.soldButton:hover {
    background-color:#ff4d008a;
}

.shareButton {
    position: relative; 
    display: inline-block;
    margin-top: 1rem; 
    background-color: #ff4d00;
    border: none;
    color: white;
    padding: 7px 16px;
    border-radius: 3px;
    cursor: pointer; 
    font-size: 1rem;
}
