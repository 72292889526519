
.homepageContainer {
    padding-left: 10vw;
    padding-right: 10vw;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

.title {
    margin-left: 10px;
    color: #ff4d00;
    font-size: 16px;
    font-weight: 600;
}

.categoryTitle {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #fff8ec;
    border-radius: 5px;
    box-shadow: 0px 1px rgba(255,77,0,0.30);
    padding: 3px;
}

.hyperLinks {
    margin-left: 20px;
    height:100%;
    color:rgb(167, 165, 165);
    transition: color 0.2s ease-in;
    cursor:default;
}

.hyperLinks:hover {
    color:#f9530b;
}

.moreItemsLinks {
    color: gray;
    margin-left:auto;
    margin-right:10px;
    transition: color 0.2s ease-in;
    cursor: default;
}

.moreItemsLinks:hover {
    color: #f9530b;
}

.cardsContainer {
    display: flex;
    flex-wrap:wrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    width: 100%;
    justify-content: center;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown button {
    background-color: transparent;
    border: none;
    outline: none;
    color: #ff4d00;
    padding-left: 10px;
}
  
.dropdownContent {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    display: none;
    background-color: #fff;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    border: 1px solid #ddd;
}
  
.dropdownContent.show {
    display: block;
}
  
.dropdownContent div {
    cursor: pointer;
    padding: 10px;
    white-space: nowrap;
}
  
.dropdownContent div:hover {
    background-color: #f1f1f1;
}

.outerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
}
  
.iconGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    gap: 20px;
    text-align: center;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}
  
.iconContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}
  
.iconButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border-radius: 12px;
    padding: 10px;
    width: 80px;
    height: 80px;
    border: 2px solid #D8D8D8;
    transition: transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.iconGrid > :nth-last-child(1):nth-child(3n + 1) {
    grid-column: 2 / 3;
}
  
.iconButton:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
  
.iconLabel {
    margin-top: 8px;
    font-size: 15px;
    font-weight: 500;
    color: #333;
}

.umiunilogo {
    width: 100%;
    height: 100%;
    object-fit: contain;
}