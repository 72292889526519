
.headerContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
}


.header {
    padding-top: 60px;
    margin-left:auto;
    margin-right:auto;

    color: #ff4d00;
}

.chatsContainer {
    width:100%;
    display: flex;
    flex-direction: column;
}

