.header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #fff8ec;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  color: #f0662f;
  padding: 0 10%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.expandedHeader {
  flex-direction: row;
  align-items: flex-start;
  height: auto;
}

.logo {
  height: 2.5rem;
  cursor: pointer;
  margin-right: 10px;
  flex-shrink: 0;
}

.searchbar {
  flex-grow: 1;
  max-width: 250px;
  margin: 0 10px;
}

.headerButton {
  display: flex;
  gap: 10px;
  margin-right: 10px;
}

.user {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
}

.userContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.vipBadge {
  background-color: #ffd700;
  color: #000;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
}

@media (max-width: 1138px) {
  .header {
    justify-content: space-between;
  }

  .expandedHeader {
    flex-wrap: wrap;
    align-items: center;
  }

  .logo, .searchbar, .headerButton, .user {
    flex-basis: auto;
    margin-bottom: 5px;
  }
}

@media (max-width: 768px) {
  .header {
    padding: 0 5%;
  }

  .searchbar, .headerButton, .user {
    flex-basis: 100%;
    margin-top: 5px;
    text-align: center;
  }

  .user {
    justify-content: center;
  }
}
