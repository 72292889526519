.navBar {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.individualButton {
    color: #555555;
    text-decoration: none; 
}

.individualButton:hover {
    color: #ff4d00;
}