.mainContainer {
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.uploadContainer {
    max-width: 60rem;
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    margin: 0 auto; 
}

.uploadSection {
    width: 100%;
    margin-top: 4rem;
}

.formContainer {
    display: flex;
    flex-direction: column;
    position: relative;
}

.formContainer .input {
    padding-left: 5%;
    width: 70%;
    display: flex;
    flex-direction: column;
}

.updateButton {
    position: absolute;
    bottom: -3rem;
    right: 0;
    background-color: #ff4d00;
    border: none;
    margin-right: 10%;
    color: white;
    padding: 7px 16px;
    z-index:5;
    border-radius: 3px;
}