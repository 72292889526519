
.links {
    color:#f0662f;
}

.links:hover {
    color:darkorange;
}

.links:active {
    
}

.navLinks {
    display: flex;
    align-items:center; 
    gap: 20px;
    font-style: normal;
  }