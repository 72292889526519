.schooldropdown{
  display: inline-block; 
  height: 30px;
  padding: 0;
  border: none;
  background: none;
  color: #ff4d00;
  white-space: nowrap;
}

.button {
  display: flex;
  align-items: center;
  position: fixed;
}

.dropdowncontent {
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  max-width: 350px;
  border: 1px solid #ff4d00;
  border-radius: 5px;
  font-size: 12px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

@media (max-width: 768px) {
  .dropdowncontent {
    position: fixed;
    right: 5vw;
    width: 80vw;
    min-width: 160px;
    max-width: 350px;
  }
}

.dropdown {
  padding: 12px 16px;  
}

.dropdown .message {
  display: block;
  margin-bottom: 10px;
}

.dropdown button {
  width: 100%;
  padding: 10px 100px;
  display: block;
  cursor: pointer;
  margin: auto;
  background-color: #fff;
  border: 1px solid #ff4d00;
  border-radius: 5px;
  font-size: 12px;
}

@media (max-width: 768px) {
  .dropdown button {
    max-width: calc(100 - 1rem);
    padding: 10px;
  }
}

.dropdown button:hover {
  background-color: #ddd;
}

.message {
  text-align: center;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.popupContent {
  background-color: #fff;
  padding: 20px;
  width: 37.5%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  top: 10px;
  position: absolute;
  width: 90vw;
  border-radius: 5px;
}

.popupContent h2 {
  margin-top: 0;
}

.popupContent ul {
  list-style: none;
  align-items: left;
  text-Align: left;
  padding: 0;
  margin: 0;
}

.popupContent ul li {
  margin-bottom: 10px;
  display: flex;
  width: 100%;
}

.popupContent ul li:hover {
  background-color: #ddd;
}

.popupContent button {
  width: 100%;
  text-align: left;
  border: none;
  background: none; 
  display: inline-block;
  margin: 0;
  padding: 0;
}

.popupContent button:hover {
  background-color: #ddd;
}

.arrowUp,
.arrowDown {
    margin-left: 5px;
    white-space: nowrap;
    display: inline-block; 
}

.arrowUp {
    transform: rotate(-180deg);
}