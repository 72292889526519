
.userManagePage {
    background-color: #ffffff;
    border: solid 1px;
    border-color: #ff4d00;
    border-radius: 5px;
    position: fixed;
    width: 70%;
    height: 70%;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.listingSelect {
    display:flex;
    justify-content: center;
    width: 100%;
    height: 5vh;
    color: #00B2FF;
}

.tabActive {
    border-bottom: 3px solid #ff4d00;
    color: #ff4d00;
    font-size: 16px;
}


.listingType {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    background-color: #fec889;
    border-right: solid 1px #00b2ff;
    transition: border 0.1s ease-in;
}

.listingType:last-child {
    border-right: none;
}



.listings {
    overflow-x: hidden;
    white-space: nowrap;
    height: 100%;
    margin-top: 1vh;
}

.tabContent {
    display: inline-block;
    width: 100%;
    min-width: 100%;
    flex-wrap: wrap;
}

.nestContent {
    display: flex;
    flex-wrap: wrap;
} 

@media (max-width: 768px) {
    .userManagePage {
      width: 90%;
      position: relative;
    }
}