
.userProfilePage {
  background-color: #ffffff;
  border: solid 1px;
  border-color: #ff4d00;
  border-radius: 5px;
  position: fixed;
  width: 70%;
  height: 70%;
  padding: 10px;
}

.profileTop {
  border-bottom: solid 1px;
  border-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
  margin-bottom: 20px;
}

.title {
  font: sans-serif;
  font-size: 30px;
  color: #ff4d00;
  font-weight: 400;
  border: none;
  outline: none;
  margin-left: 10px;
  margin-top: -10px;
  box-shadow: none;
}


.imageContainer {
  height: 250px;
  width: 250px;
  display: flex;
  flex-direction: column;
}

.avatarContainer {
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
  border: #ff4d00 solid 2px;
}

.avatarContainer:hover .avatar {
  filter: brightness(80%); /* Adjust the value (50%) to your desired shade */
}

.avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: filter 0.3s ease;
}

.subtitle {
  font: sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #ff4d00;
}


.subcontent {
  font-size: 16px;
  margin-left: 3%;
}

.searchBar {
  width: 80%;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  color: #ff4d00;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  outline: none;
}

.searchResultContainer {
  overflow-y: scroll;
  max-height: 14%;
  border: 2px solid;
  border-color: #ff4d00;
  background-color: #ffffff;
  border-radius: 5px;
  position:fixed;
  list-style-type: none;
  width: 23.5%;
  z-index: 9999;
}

.searchResults {
  padding-left: 3%;
  overflow-x: hidden;
  cursor: pointer;
}

.searchResults:hover {
  background-color:rgba(0, 0, 0, 0.1);
}

.updateButton {
  background-color: #ff4d00;
  float: right;
  border: none;
  margin-right: 5%;
  color: white;
  padding: 7px 16px;
  z-index:5;
  border-radius: 3px;
}

.progressBar {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin: 10px 0;
}

.progress {
  height: 10px;
  background-color: #ff4d00;
  transition: width 0.3s ease;
}

@media (max-width: 768px) {
  .userProfilePage {
    width: 90%;
    position: relative;
  }

  .searchResultContainer {
    width: 75%;
    background-color: #ffffff;
    z-index: 9999;
  }

  .avatarContainer {
    width: 30vw;
    height: 30vw;
  }
}