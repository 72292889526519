.container {
  padding-top: 8vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 100vw;
  align-items: center;
}

.container h1 {
  padding: 10px 0 20px;
}

.container p {
  font-size: 1.3em;
}
