.displayCard {
    background-color: white;
    box-sizing: border-box;
    border: 1px solid;
    border-radius: 8px;
    border-color: rgba(255, 77, 0, 0.3);
    position: relative;
    width: 100%;
    min-height:170px;
    max-height:33vh;
    margin: auto;
    transition:max-height 0.8s ease-in-out;
    overflow:hidden;
}

.displayCard:hover {
    max-height: 70vh;
}

.displayCard:hover .additionalCardInformation {
    opacity: 1;
    visibility: visible;  
}

.displayCard:hover .sellerInformation {
    opacity: 1;
    visibility: visible;  
}

.displayCard:hover .cardImage {
    filter:brightness(80%);
}

.cardIamge {
    filter:brightness(100%);
}

.displayCard:hover .itemName {
    overflow: visible;
    white-space: wrap;
}

.displayCard:not(:hover) {
    transition-delay: 0.2s;
}

.displayCard:not(:hover) .additionalCardInformation {
    transition: opacity 0.2s ease-out;
}

.displayCard:not(:hover) .sellerInformation {
    transition: opacity 0.2s ease-out;
}

.displayCard:hover .addtionalCard {
    max-height: 214748px;
}

.additionalCardInformation {
    padding-left: 5px;
    padding-right: 3px;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
    transition-delay: 0.2s;
    border-top: 1px solid rgba(255, 77, 0, 0.3);
}

.sellerInformation {
    padding-left: 5px;
    padding-right: 3px;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
    transition-delay: 0.2s;
    border-top: 1px solid rgba(255, 77, 0, 0.3);
}

.additionalFunction {
    float: right;
    display: inline-block;
    cursor: pointer;
    font-size: 1rem;
}

.sellerInformation:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

.additionalCard {
    border: 2px solid;
    border-color: #e0e0e0;
    background-color:white;
    max-height: 0px;
    transition: max-height 0.8s ease-in-out;
}

.cardBody {
    padding-left: 5px;
    padding-right: 3px;
}

.link {
    text-decoration: none;
}
  
.itemName {
    
    font-size: 18px;
    color: black;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    transition: whiteSpace 0.0s ease;
    transition-delay: 0.5s;
}
  
.cardImageContainer {
    width: 100%;
    height: 25vh;
    display:inline-block;
    position: relative;
}
  
.cardImageContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    border: 0px solid;
    
    border-color:white;
    border-radius:8px; 
}
  
.itemColumn {
    display:flex;
    flex-direction: column;
    min-width: 15.8%;
    max-width: 15.8%;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 3vh;
}

@media (max-width: 652px) {
    .itemColumn {
      min-width: 45%;
      max-width: 45%;
    }
}

@media (min-width: 652px) and (max-width: 1200px){
    .itemColumn {
      min-width: 32%;
      max-width: 32%;
    }
}
  
.tagCover {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 4px 8px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
}

.extraTag {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 4px 8px;
    background-color: #ff4d00;
    color: #fff;
}
  
.avatar {
    border-radius: 100%;
    width:15%;
    height: 15%;
}

