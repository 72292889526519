
.dropdownField {
    align-items: center;
    margin: 0;
    padding: 0;
    margin-left:auto;
    display: flex;
    height: 3rem;

}

.banner {
    margin: 0;
}

.dropdownField img {
    width: 100%; /* Set the width to fill the available space */
    height: 30px; /* Maintain aspect ratio */
    max-width: 30px; /* Set a maximum width if desired */
    border-radius: 100%;
    object-fit: cover;
}

.dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 999;
    display: none;
    margin-top: 5%;
}

.dropdown_active {
    position: absolute;
    margin: 0;
    padding: 0;
    color: #ff4d00;
    font-size: 12px!important;
    transition: background .2s ease-in-out;
    list-style: none;
    white-space: nowrap;
    clear: both;
    display: block;
    background-color:rgb(255, 255, 255);
    border-radius: 4px;
    border: 1px solid;
    top:105%;
    z-index: 999;
}

.dropdown_active li {
    width: 100%;
    margin: 7px 8px;
    padding-left: 5px;
    cursor: pointer;
    border-left: solid 5px lightblue;
}

.dropdown_active li:last-child {
    border-bottom: none;
}

.dropdown_active li:hover {
    border-left: solid 5px rgb(68, 201, 245);
}

.postButton {
    height: 30px;
    width: 60px;
    background-color: #fff;
    border: 1px solid #ff4d00;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#ff4d00;
    text-decoration: none;
    transition: color 0.2 ease;
    
}

.postButton:hover {
    background-color: #ff4d00;
    color: #fff;
}

.navLinks {
    display: flex;
    align-items:center; 
    gap: 1rem;
    font-style: normal;
}
