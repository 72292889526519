/* Overall container */
.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    text-align: center;
    padding: 20px;
}

/* Line of Words */
.line-of-words {
    font-size: 1.5em;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
    width: 100%;
}

/* Tabs Wrapper */
.tabs-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

/* Tabs Container */
.tabs-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); /* Flexible grid for all screens */
    gap: 20px;
    width: 100%;
    max-width: 1200px;
    padding: 0 10px; /* Add padding for smaller screens */
    box-sizing: border-box; /* Include padding in width calculation */
    overflow-x: auto; /* Allow horizontal scrolling if necessary */
}

/* Tab */
.tab {
    border: none;
    background: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: #e0e0e0;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
    color: #000;
}

.tab:hover {
    transform: scale(1.05); /* Zoom effect */
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.tab:focus {
    outline: none; /* Remove focus outline */
}

/* Tab Image */
.tab-image {
    width: 100%;
    height: auto;
    border: 2px solid #ff4d00; /* Orange border around the image */
    border-radius: 10px;
}

.tab-image img {
    width: 100%;
    border-radius: 10px;
}

/* Tab Info */
.tab-info {
    text-align: center;
}

.tab-info h3 {
    font-size: 18px;
    margin: 10px 0;
    color: #000;
}

.tab-info p {
    font-size: 14px;
    margin: 0;
    color: #333;
}

/* Login Button */
.login-btn {
    background-color: #fff;
    color: #ff4d00;
    border: 1px solid #ff4d00;
    border-radius: 50px;
    padding: 10px 30px;
    font-size: 1.2em;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.login-btn:hover {
    background-color: #ff4d00;
    color: #fff;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .tabs-container {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Adjust grid for medium screens */
    }
}

@media (max-width: 768px) {
    .tabs-container {
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); /* Adjust grid for tablets */
    }

    .tab {
        padding: 15px;
    }

    .tab-info h3 {
        font-size: 16px;
    }

    .tab-info p {
        font-size: 12px;
    }

    .login-btn {
        font-size: 1em;
        padding: 8px 20px;
    }

    .line-of-words {
        font-size: 1.4em;
    }
}

@media (max-width: 480px) {
    .tabs-container {
        display: flex; /* Switch to flex for scrolling */
        flex-wrap: nowrap; /* Prevent wrapping */
        gap: 10px;
        overflow-x: auto; /* Enable horizontal scrolling */
        padding-bottom: 10px;
    }

    .tab {
        flex: 0 0 auto; /* Ensure each tab maintains its width */
        width: 120px; /* Fixed width for tabs on small screens */
        padding: 10px;
    }

    .tab-info h3 {
        font-size: 14px;
    }

    .tab-info p {
        font-size: 10px;
    }

    .line-of-words {
        font-size: 1.2em;
        margin-bottom: 15px;
    }
}
